<mjx-input class="custom-input" [rounded]="rounded">
  <mat-label>{{ name | translate }}</mat-label>
  <mat-form-field floatLabel="never">
    <input type="text"
           [placeholder]="placeholder | translate"
           [readonly]="readonly"
           [disabled]="disabled"
           matInput
           [formControl]="selectControl"
           [matAutocomplete]="auto">
    <mat-autocomplete class="custom-autocomplete" #auto="matAutocomplete" [displayWith]="displayFnWrapper()" (opened)="showAllOptions()" (optionSelected)="selectChanges.emit($event)">
      <mat-option *ngIf="loadingOptions" class="loading-option">
        <mat-spinner class="custom-spinner" diameter="16" mode="indeterminate"></mat-spinner>
      </mat-option>
      <mat-option *ngIf="emptyOptions" class="custom-option" [disabled]="true">
        {{ emptyLabel | translate }}
      </mat-option>
      <mat-option *ngFor="let option of (filteredOptions$ | async)" [value]="option" class="custom-option">
        <ng-container *ngIf="customOptionsTmpl"
                      [ngTemplateOutlet]="customOptionsTmpl"
                      [ngTemplateOutletContext]="{ $implicit: option}">
        </ng-container>
        <ng-container *ngIf="!customOptionsTmpl">
          {{ option[filterProperty] }}
        </ng-container>
      </mat-option>
    </mat-autocomplete>
    <div matPrefix>
      <ng-content select=".prefix"></ng-content>
    </div>
  </mat-form-field>
</mjx-input>
