<div class="mat-elevation-z8 table-container" [ngClass]="{ 'infinity-table': infinityTable }" (scroll)="onScroll($event)">
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let column of columnsToDisplay; index as i">
      <div mat-menu-item (click)="$event.stopPropagation()" [ngClass]="{blockMouse: column.disabled}">
        <mat-checkbox style="height: auto; width: auto"
                      [ngClass]="{blockMouse: column.disabled}"
                      [disabled]="column.disabled"
                      [(ngModel)]="column.show"
                      (change)="saveStatesToStorage()"
        >
          <span (mousedown)="$event.stopPropagation()" (click)="checkLabel($event)">
            {{ column.name | translate }}
        </span>

        </mat-checkbox>
      </div>
    </ng-container>
  </mat-menu>

  <div class="emptyColumns" *ngIf="isAllColumnsFiltered() && showColumnsFilter">
    {{'FILTERED_COLUMNS_EMPTY' | translate}}
  </div>

  <div class="newPaginator" [ngClass]="{'flex': hiddenPagination}">
    <mat-paginator class="custom-paginator" #paginator  [hidden]="hiddenPagination"
                   [length]="hafTable ? (tableDataSource?.total / 2) : tableDataSource?.total"
                   [pageIndex]="0"
                   [pageSize]="defaultPageSize"
                   (page)="emitEvent('table-pagination', $event)"
                   [pageSizeOptions]="defaultPageSizeOptions"
                   [showFirstLastButtons]="true"
                   aria-label="Select page">
    </mat-paginator>

    <ng-container [ngTemplateOutlet]="verticalLine" *ngIf="showColumnsFilter"></ng-container>

    <button mat-icon-button
            *ngIf="showColumnsFilter"
            [matTooltipHideDelay]="10"
            [matTooltipPosition]="'above'"
            [disabled]="false"
            matTooltip="{{ 'FILTER_COLUMNS' | translate }}"
            [matMenuTriggerFor]="menu"
            class="customTableColumns">
      <mat-icon [ngClass]="{hasColumnsFilter: hasColumnsFilter()}">settings</mat-icon>
    </button>
  </div>

  <div style="overflow: auto">
    <table mat-table class="mjx-table custom-table" [ngClass]="{'table-disable': disableTable}" matSort aria-label="Elements"
           (matSortChange)="listenChange($event)" multiTemplateDataRows
    >
      <ng-container [matColumnDef]="columDef.column" *ngFor="let columDef of columnsDefinitions; let last = last">
        <th class="tableHeaders" mat-header-cell *matHeaderCellDef [disabled]="!columDef.sortable" [mat-sort-header]="columDef.property">
          <span>{{ columDef.name | translate }}</span>
          <button mat-icon-button *ngIf="columDef?.headerSuffix">
            <mat-icon class="header-icon"
                      (click)="emitEvent(columDef.headerSuffix.action, null); $event.stopPropagation()"
                      [ngStyle]="{ 'color': columDef.headerSuffix.color }"
                      *ngIf="columDef?.headerSuffix">
              {{columDef.headerSuffix.icon}}
            </mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let item" [ngClass]="{'body-disable': disableTable}">
          <ng-container [ngTemplateOutletContext]="{ $implicit: item }"
                        [ngTemplateOutlet]="columnsRefs[columDef.column] || defaultContent">
          </ng-container>
          <ng-template #defaultContent>
            <ng-container [ngSwitch]="columDef.type">
              <ng-container *ngSwitchCase="columnTypes.Number">
                <span>{{item[columDef.property] | number}}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Percentage">
                <span>{{item[columDef.property]}} %</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Currency">
                <span>{{item[columDef.property] | mjxCurrency}}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.CpfCnpj">
                <span>{{item[columDef.property] | mask: 'CPF_CNPJ'}}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Date">
                <span>{{item[columDef.property] | date: 'dd/MM/yyyy'}}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.DateHour">
                <div class="center-date">
                  <span>{{item[columDef.property] | date: 'dd/MM/yyyy'}}</span>&nbsp;
                  <span>{{item[columDef.property] | date: 'HH:mm:ss'}}</span>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.DateHourMillis">
                <div class="center-date">
                  <span>{{item[columDef.property] | date: 'dd/MM/yyyy'}}</span>&nbsp;
                  <span>{{item[columDef.property] | date: 'HH:mm:ss.SSS'}}</span>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Array">
                <span>{{ (columDef.transform ? columDef.transform(item[columDef.property]) : item[columDef.property]) | translate }}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.ArrayObject">
                <span>{{ (columDef.transform ? columDef.transform(item[columDef.property]) : item[columDef.property]) | translate }}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Phone">
                <span>{{item[columDef.property] | mask: '+00 (00) 00000-0000||+00 (00) 0000-0000'}}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Link">
                <span class="link-item" (click)="emitEvent('link', item)">
                  {{item[columDef.property] | translate}}
                </span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Icon">
                <span>{{item[columDef.property]}}</span>
              </ng-container>

              <ng-container *ngSwitchCase="columnTypes.Actions">
                <div class="table-actions-container">
                  <ng-container *ngFor="let action of columDef?.actions">
                    <mjx-table-action-button [action]="action" [item]="item"
                                             (buttonEvent)="emitButtonEvent($event)"></mjx-table-action-button>
                  </ng-container>
                </div>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <span>{{getValue(item, columDef) | translate}}</span>
              </ng-container>
            </ng-container>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let item" [attr.colspan]="getDisplayedColumns().length">
          <ng-container [ngTemplateOutlet]="columnsRefs['expandedDetail']" [ngTemplateOutletContext]="{ $implicit: item }">
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="footer">
        <td mat-footer-cell *matFooterCellDef="let element" [attr.colspan]="columnsToDisplay?.length">
          <ng-container [ngTemplateOutlet]="columnsRefs['footer']" [ngTemplateOutletContext]="{$implicit: element}">
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>

      <tr mat-row [ngClass]="{'total-table': totalTable}" *matRowDef="let row; columns: getDisplayedColumns()" (click)="expandRow(row)"></tr>

      <tr [hidden]="expandedElement === row ? null : row" mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expanded-row"></tr>

      <tr [hidden]="!showFooter" mat-footer-row *matFooterRowDef="['footer']"></tr>
    </table>
  </div>


</div>

<ng-template #verticalLine>
  <div class="vertical-line"></div>
</ng-template>

<ng-template #suffix let-columnDef>
  <mat-icon
    (click)="$event.preventDefault(); emitEvent(columnDef.dataSuffix.action, null)"
    [ngStyle]="{ 'color': columnDef.dataSuffix.color }"
    *ngIf="columnDef?.dataSuffix">
    {{columnDef.dataSuffix.icon}}
  </mat-icon>
</ng-template>
