<mat-sidenav-container class="sidenav-container">
    <mat-sidenav *ngIf="!hideNavigation" #drawer class="sidenav" [(opened)]="isMenuOpen"
                 fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'">
      <ng-container>
        <div class="user-info-container">
          <div class="top-custom"></div>
          <img class="logo" alt="Logo" [ngClass]="'logoCustom'" src="{{ getCompanyLogo() }}">

          <div class="user-options">
            <div class="user-data" (click)="goToProfileForm()">
              <ng-container *ngIf="(currentUser$ | async) as user; else loadingUserData">
                <span class="name">{{ user?.fullName }}</span>
                <br>
                <span class="email">{{ user?.email }}</span>
              </ng-container>
            </div>
            <div class="logout-container">
              <ng-container>
                <mjx-button class="yellow-text"
                  (click)="doLogout()" icon="exit_to_app" [onlyIcon]="true"></mjx-button>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <mat-nav-list class="menu-list">
        <div class="d-flex">
          <div class="main-menus" [ngStyle]="{ 'width': isOpen ? 'auto' : '100%' }">
            <ng-container *ngIf="isOpen">
              <div (mouseenter)="openMenu(menu)"
                   *ngFor="let menu of menuItems" class="icon-btn-container"
                   [ngClass]="{ 'selected-btn': menu.title === selectedMenu?.title }">
                <div class="tag"></div>
                <button mat-icon-button class="menu-icon">
                  <mat-icon>{{ menu.icon }}</mat-icon>
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="!isOpen">
              <button (click)="openMenu(menu)"
                      mat-button class="menu-label" *ngFor="let menu of menuItems">
                <mat-icon>{{ menu.icon }}</mat-icon>
                <span>{{ menu.title | translate }}</span>
              </button>
            </ng-container>
          </div>

          <div class="opened-menu" *ngIf="isOpen">
            <div class="menu-title">
              <button mat-icon-button (click)="isOpen = !isOpen">
                <mat-icon>chevron_left</mat-icon>
              </button>
              <span>{{selectedMenu?.title | translate | uppercase}}</span>
            </div>
            <ng-container *ngFor="let menu of selectedMenu?.menus">
              <mjx-button class="menu-button" *ngIf="!(menu.menus)"
                          mat-list-item
                          [routerLink]="menu.routerLink"
                          routerLinkActive
                          #rla="routerLinkActive"
                          (click)="setSelectedButton(menu)"
                          [selected]="rla.isActive"
                          [icon]="menu.icon"
                          [name]="menu.title | translate">
              </mjx-button>
              <ng-container *ngIf="menu.menus">
                <mat-accordion>
                  <mat-expansion-panel class="expand-painel">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-icon class="menu-icon">{{menu.icon}}</mat-icon>
                        <span>{{ menu.title | translate }}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container *ngFor="let secMenu of menu.menus">
                      <mjx-button class="menu-button secondary-menu-button"
                                  *ngIf="!(secMenu.menus)"
                                  mat-list-item
                                  [routerLink]="secMenu.routerLink"
                                  routerLinkActive
                                  #rla="routerLinkActive"
                                  (click)="setSelectedButton(menu, secMenu)"
                                  [selected]="rla.isActive"
                                  [icon]="secMenu.icon"
                                  [name]="secMenu.title | translate"
                      ></mjx-button>
                      <ng-container *ngIf="secMenu.menus">
                        <mat-expansion-panel class="expand-painel-second">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <mat-icon class="menu-icon">{{secMenu.icon}}</mat-icon>
                              <span>{{ secMenu.title | translate }}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <mjx-button class="menu-button expanded-button"
                                      *ngFor="let thirdMenu of secMenu.menus"
                                      [routerLink]="thirdMenu.routerLink"
                                      routerLinkActive
                                      #rla="routerLinkActive"
                                      (click)="setSelectedButton(secMenu, thirdMenu)"
                                      [selected]="rla.isActive"
                                      [icon]="thirdMenu.icon"
                                      [name]="thirdMenu.title | translate"
                          ></mjx-button>
                        </mat-expansion-panel>
                      </ng-container>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="filterStyleRules">
      <div class="sidenav-content">
        <mat-toolbar color="primary" *ngIf="!hideNavigation">
          <button class="yellow-text"
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="matSideNav.toggle()">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <div class="toolbar-options">
            <ng-container *hasPermission="{ roles: rolesEnum.PARTNERS_ALL }" [ngTemplateOutlet]="selectAccount"></ng-container>
            <ng-container *hasPermission="{ roles: rolesEnum.PARTNERS_ALL }" [ngTemplateOutlet]="verticalLine"></ng-container>
            <ng-container [ngTemplateOutlet]="selectLanguage"></ng-container>
            <ng-container *ngIf="!(isMobile$ | async)" [ngTemplateOutlet]="verticalLine"></ng-container>
            <ng-container *ngIf="!(isMobile$ | async)" [ngTemplateOutlet]="userProfile"></ng-container>
          </div>
        </mat-toolbar>
        <ng-content></ng-content>
        <ng-content select="footer"></ng-content>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #selectAccount>
  <mjx-select-account class="col-auto select-account-option" *ngIf="(currentUser$ | async)"></mjx-select-account>
</ng-template>

<ng-template #selectLanguage>
  <button class="select-lang-btn col" mat-button [matMenuTriggerFor]="menu" aria-label="Select Language" [disabled]="loadingLanguage">
    <img [src]="selectedLanguage.image" class="lang-icon">
    <span>{{ selectedLanguage.name }}</span>
    <mat-spinner class="custom-spinner" *ngIf="loadingLanguage" diameter="20"></mat-spinner>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let lang of languages">
      <button class="select-lang-btn" mat-menu-item (click)="changeLanguage(lang)">
        <img [src]="lang.image" class="lang-icon">
        <span>{{ lang.name }}</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-template>

<ng-template #verticalLine>
  <div class="vertical-line"></div>
</ng-template>

<ng-template #userProfile>
  <button class="user-profile"
    type="button"
    [matTooltip]="'NAVBAR.USER_TOOLTIP' | translate"
    aria-label="User Profile"
    (click)="goToProfileForm()"
    mat-button>
    <mat-icon class="user-icon" aria-label="User Profile">person</mat-icon>
    <ng-container class="user-name" *ngIf="(currentUser$ | async) as user; else loadingUserData">
      <span>{{ user?.fullName }}</span><br>
    </ng-container>
  </button>
</ng-template>

<ng-template #loadingUserData>
  <mat-spinner diameter="32" mode="indeterminate" class="custom-spinner"></mat-spinner>
</ng-template>
