import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MjxNavigatorModule} from "./shared/modules/mjx-navigator/mjx-navigator.module";
import {SessionGuard} from "./core/guards/session.guard";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {environment} from "../environments/environment";
import {MATERIAL_SANITY_CHECKS} from "@angular/material/core";
import {AuthInterceptor} from "./core/interceptors/auth.interceptor";
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en'
import ptBr from '@angular/common/locales/pt';
import {NgxsModule} from "@ngxs/store";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";
import {AuthState} from "./core/state/auth/auth.state";
import {decrypt, encrypt} from "./core/utils/state-encode";
import {CustomTranslateLoaderFactory} from "./core/utils/custom-translate-loader";
import {isEnglish} from "./shared/utils/language-utils";
import {ErrorsState} from "./shared/states/errors/errors.state";
import { VersionsDialogComponent } from './shared/components/versions-dialog/versions-dialog.component';
import { MaterialElementsModule } from './shared/modules/material-elements/material-elements.module';
import { MjxButtonModule } from './shared/modules/mjx-button/mjx-button.module';
import { MjxInputModule } from './shared/modules/mjx-input/mjx-input.module';

registerLocaleData(isEnglish() ? en : ptBr);

@NgModule({
  declarations: [
    AppComponent,
    VersionsDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MjxNavigatorModule,
    MjxButtonModule,
    MaterialElementsModule,
    MjxInputModule,

    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLang,
      loader: {
        provide: TranslateLoader,
        useFactory: CustomTranslateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxsModule.forRoot([AuthState, ErrorsState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      serialize: encrypt,
      deserialize: decrypt
    })
  ],
  providers: [
    SessionGuard,
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: isEnglish() ? 'en' : 'pt'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
