import {AccessRolesEnum} from "../enums/access-roles.enum";


export const Roles = {

  SALES_HOME_PAGE: [
    AccessRolesEnum.PartnerMember,
    AccessRolesEnum.CorporateMember,
    AccessRolesEnum.CorporateSupport
  ],

  CORPORATE: [AccessRolesEnum.Corporate],
  CORPORATE_ADMIN: [AccessRolesEnum.CorporateAdmin],
  CORPORATE_MEMBER: [AccessRolesEnum.CorporateMember],
  CORPORATES_ALL: [
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.Corporate,
    AccessRolesEnum.CorporateMember,
    AccessRolesEnum.CorporateSupport
  ],

  PARTNER: [AccessRolesEnum.Partner],
  PARTNER_MEMBER: [AccessRolesEnum.PartnerMember],
  PARTNER_ADMIN: [AccessRolesEnum.PartnerAdmin],
  PARTNERS_ALL: [
    AccessRolesEnum.PartnerMember,
    AccessRolesEnum.Partner,
    AccessRolesEnum.PartnerAdmin
  ],

  ALL: [
    AccessRolesEnum.Partner,
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.PartnerMember,
    AccessRolesEnum.Corporate,
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.CorporateMember,
    AccessRolesEnum.CorporateSupport
  ],

  EMPTY: [],

  CORPORATE_PARTNER: [AccessRolesEnum.Corporate, AccessRolesEnum.Partner],

  ADMINS: [AccessRolesEnum.PartnerAdmin, AccessRolesEnum.CorporateAdmin],
  ADMINS_AND_FINANCIAL: [
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.PartnerMemberFin
  ],

  MEMBERS: [AccessRolesEnum.PartnerMember, AccessRolesEnum.CorporateMember],

  FINANCIAL: [
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.PartnerMemberFin
    ],

  ADMINS_AND_DEFAULTS: [
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.Corporate,
    AccessRolesEnum.Partner
  ],

//  RECEIVERS
  RECEIVER_REVISION_ACTION:[
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.Corporate,
  ],

  RECEIVER_EDIT_ACTION:[
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.PartnerMemberFin
  ],

  RECEIVER_DELETE_ACTION:[
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.PartnerMemberFin
  ],

  RECEIVER_APPROVE_REJECT:[
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.Corporate,
  ],

//   WITHDRAWS
  WITHDRAWS_LIST:[
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.Corporate,
  ],

// PARTNERS
  PARTNER_CREATE_NEW: [
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.Corporate,
  ],
  PARTNER_DELETE_ACTION: [
    AccessRolesEnum.CorporateAdmin
  ],

//   PAYMENTS
  PAYMENTS_CREATE_SAVE: [
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.PartnerMemberFin
  ],


  //   USER PROFILE
  USER_PROFILE: [
    AccessRolesEnum.PartnerAdmin,
    AccessRolesEnum.CorporateAdmin,
    AccessRolesEnum.Corporate,
    AccessRolesEnum.CorporateMember,
  ],

//   MENU AND ROUTES ACCESS
  MENU_ROUTES: {
    ADMINISTRATIVE: [
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.CorporateMember,
      AccessRolesEnum.Corporate
    ],
    FINANCES: [
      AccessRolesEnum.CorporateAdmin
    ],
    ENTITIES: [
      AccessRolesEnum.CorporateAdmin
    ],
    CONFIGURATIONS: [
      AccessRolesEnum.CorporateAdmin
    ],
    MERCHANTS: [
      AccessRolesEnum.CorporateAdmin
    ],
    ACCOUNTS: [
      AccessRolesEnum.CorporateAdmin,
    ],
    BANKS: [
      AccessRolesEnum.CorporateAdmin,
    ],
    HOME: [
      AccessRolesEnum.PartnerAdmin,
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.Corporate
    ],
    HEATMAP: [
      AccessRolesEnum.PartnerAdmin,
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.Corporate
    ],
    SALES: [
      AccessRolesEnum.Partner,
      AccessRolesEnum.PartnerAdmin,
      AccessRolesEnum.PartnerMember,
      AccessRolesEnum.Corporate,
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.CorporateMember,
      AccessRolesEnum.CorporateSupport
    ],
    WITHDRAWS: [
      AccessRolesEnum.Partner,
      AccessRolesEnum.PartnerAdmin,
      AccessRolesEnum.PartnerMember,
      AccessRolesEnum.Corporate,
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.CorporateMember,
      AccessRolesEnum.CorporateSupport
    ],
    RECEIVERS: {
       LIST: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.PartnerMemberFin,
        AccessRolesEnum.CorporateAdmin,
        AccessRolesEnum.Corporate,
        AccessRolesEnum.CorporateMember
      ],
      BATCH: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.PartnerMemberFin
      ],
      NEW: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.PartnerMemberFin
      ],
      EDIT: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.PartnerMemberFin
      ],
      REVISION: [
        AccessRolesEnum.CorporateAdmin,
        AccessRolesEnum.Corporate,
      ]
    },
    PAYMENTS: [
      AccessRolesEnum.PartnerAdmin,
      AccessRolesEnum.PartnerMemberFin,
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.Corporate
    ],
    ENTRIES: [
      AccessRolesEnum.PartnerAdmin,
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.CorporateMember,
      AccessRolesEnum.Corporate,
    ],
    TRANSACTIONS: [
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.CorporateMember
    ],
    DATA_EXPORT: [
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.CorporateMember,
      AccessRolesEnum.Corporate
    ],

    USERS: {
      MANAGEMENT: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.CorporateAdmin,
        AccessRolesEnum.Corporate,
        AccessRolesEnum.CorporateMember
      ],
      PROFILES: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.CorporateAdmin,
        AccessRolesEnum.Corporate,
        AccessRolesEnum.CorporateMember
      ],
    },

    PARTNERS: [
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.Corporate,
      AccessRolesEnum.CorporateMember
    ],

    INTEGRATION: {
      ALL: [
        AccessRolesEnum.PartnerAdmin
      ],
      ACCESS_KEYS: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.CorporateAdmin,
      ],
      WEBHOOKS: [
        AccessRolesEnum.PartnerAdmin
      ],
      DOCUMENTATION: [
        AccessRolesEnum.PartnerAdmin
      ]
    },
    OPERATIONAL: [
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.CorporateSupport,
      AccessRolesEnum.Corporate
    ],
    CONFIG: {
      BANKS: [
        AccessRolesEnum.CorporateAdmin,
      ],
      POLICY: [
        AccessRolesEnum.PartnerAdmin,
        AccessRolesEnum.CorporateAdmin,
        AccessRolesEnum.Corporate,
      ],
      AUDIT: [
        AccessRolesEnum.CorporateAdmin
      ],
      CLOUD: [
        AccessRolesEnum.CorporateAdmin,
        AccessRolesEnum.Corporate
      ],
    },
    QUERY: [
      AccessRolesEnum.PartnerAdmin,
      AccessRolesEnum.PartnerMember,
      AccessRolesEnum.CorporateAdmin,
      AccessRolesEnum.Corporate,
      AccessRolesEnum.CorporateSupport
    ],
  },
  EXPORT_DATA: {
    DETAILED: [
      AccessRolesEnum.CorporateAdmin,
    ]
  }
}
