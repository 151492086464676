<div class="title-container" mat-dialog-title>
  <span>
   {{'VERSION_MODAL.TITLE' | translate}}
  </span>

  <button class="close-modal-btn" mat-icon-button mat-dialog-close cdkFocusInitial>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-divider class="dialog-divider"></mat-divider>

<div mat-dialog-content class="max-height content mt-3">
  <div [class]="(isSmallScreen$ | async) ? 'mobile-container' : 'data-container'">
    <div *ngIf="!(isSmallScreen$ | async)" class="list-versions">
      <ng-container *ngFor="let version of versions.versions">
        <div [class]="version.version === versionShowed?.version ? 'selected-card' : 'card-list'">
          <mjx-button class="menu-button expanded-button"
            mat-list-item
            routerLinkActive
            #rla="routerLinkActive"
            (click)="selectedVersion(version)"
            [selected]="version.version === versionShowed?.version"
            [name]="'Versão ' + version.version + ' ('+ version.date +')' | translate"
            [versionButton]="true"
          ></mjx-button>
          <mat-divider></mat-divider>
        </div>
      </ng-container>
    </div>

    <div *ngIf="(isSmallScreen$ | async)" class="mobile-versions">
      <div class="filter-field col-12 col-sm-4 col-md-3 col-lg-2">
        <mjx-input [rounded]="true">
          <mat-form-field floatLabel="never">
            <mat-select (valueChange)="selectedVersion($event)" [value]="versionShowed">
              <mat-option
                *ngFor="let version of versions.versions"
                [value]="version">
                <span>{{ version.version }} ({{ versionShowed.date }})</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mjx-input>
      </div>
    </div>
    <mat-divider class="vertical-divider" [vertical]="true"></mat-divider>
    <div class="versions-details">
      <mat-card *ngIf="hasVersionToShow" [class]="(isSmallScreen$ | async) ? 'mobile-card card-details' : 'card-details'">
        <mat-card-header class="margin-card">Versão {{versionShowed.version}} ({{versionShowed.date}})</mat-card-header>
        <div class="content-card">
          <div class="row">
            <div class="content-container margin-description">
              <div class="value"> <mat-icon class="icon-details">circle</mat-icon> Features:</div>
              <ul class="padding-list">
                <li class="description-item" *ngFor="let description of versionShowed.description.features">
                  {{description}}
                </li>
              </ul>
            </div>
          </div>
          <div class="row" *ngIf="versionShowed && versionShowed.description && versionShowed.description.bugs && versionShowed.description.bugs.length > 0">
            <div class="content-container margin-description">
              <div class="value"> <mat-icon class="icon-details">circle</mat-icon> Bug Fixes:</div>
              <ul class="padding-list">
                <li class="description-item" *ngFor="let description of versionShowed.description.bugs">
                  {{description}}
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="content-container margin-description">
              <div class="value"> <mat-icon class="icon-details">circle</mat-icon> Improvements:</div>
              <ul class="padding-list">
                <li class="description-item" *ngFor="let description of versionShowed.description.improvements">
                  {{description}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<mat-divider class="dialog-divider"></mat-divider>

<div mat-dialog-actions class="actions">
  <button class="custom-button" mat-button [mat-dialog-close]="false" cdkFocusInitial>
    {{'VERSION_MODAL.CLOSE' | translate}}
  </button>
</div>
