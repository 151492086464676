import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationModel } from 'src/app/shared/models/pagination.model';
import { RequestDataFilterModel } from 'src/app/shared/models/request-filter.model';
import { CommonResponse } from 'src/app/shared/models/response.model';
import { HttpUtilsService } from 'src/app/shared/services/http-utils.service';
import { environment } from 'src/environments/environment';
import { routes } from 'src/environments/vendor/routes/corporate-routes';
import {MerchantFilterModel, MerchantFormModel, MerchantModel} from "../../../modules/merchants/models/merchant.model";
import {AccountsFilterModel} from "../../../core/state/accounts/models/accounts-filter.model";
import {
  AccountModel, AccountOperationalSummary, AccountTransactionsSummary,
  FilterAccountOperationalSummary,
  FilterAccountSummaryModel, FilterAccountTransactionsSummary, FilterAnalyticsHeatmap
} from "../../../core/models/account.model";
import {AccountsStateModel} from "../../../core/state/accounts/models/accounts-state.model";
import {AccountsState} from "../../../core/state/accounts/accounts.state";
import {Store} from "@ngxs/store";
import {PartnerBillingPlan} from "../../../modules/partners/models/partner-billing-plan.model";
import {ReceiverFormModel, ReceiverModel} from "../../../modules/receivers/models/receiver.model";
import {Observable, switchMap} from "rxjs";
import {BrBankModel} from "../../models/br-bank.model";
import {BankAccountModel} from "../../../modules/receivers/models/bank-account.model";
import {CnaeModel} from "../../../modules/receivers/models/cnae.model";
import {PartnerSite} from "../../../modules/partners/models/parrner-site.model";
import {TransactionsTypesEnum} from "../../enums/transactions-types.enum";
import {TransactionResponseModel} from "../../../modules/transactions/models/transaction-response.model";
import {RecentTransactionsModel} from "../../../modules/transactions/models/recent-transactions.model";
import { TransactionRequestFilterModel } from 'src/app/modules/transactions/models/transaction-request-filter.model';
import { TransactionRequestModel } from 'src/app/modules/transactions/models/transaction-request.model';
import {
  BankOperator,
  SelectedBanksOperators
} from "../../../modules/configuration/models/bank-operator.model";
import {AccountConfigurationObject} from "../../../modules/partners/models/account-configuration-object.model";
import {PolicyModel} from "../../../modules/configuration/models/policy/policy.model";
import {PolicyRulesModel} from "../../../modules/configuration/models/policy/policy-rules.model";
import {PolicyFilterModel} from "../../../modules/configuration/models/policy/policy-filter.model";
import { ApiKeyResponseModel } from 'src/app/modules/integration/models/api-key-response.model';
import {ApiKeyReviewEnum} from "../../enums/api-key-review.enum";
import {AccountUserPreferencesObject} from "../../../modules/partners/models/account-user-preferences-object.model";
import {ParticipantsCheckResponse} from "../../../modules/queries/models/participants-check-response.model";
import {OperationBankBalance} from "../../../modules/operational/components/models/operational.model";
import {Account} from "../../../modules/accounts/models/account.model";
import {RefundTransactionModel} from "../../../modules/operational/components/models/refund-transaction.model";
import {AuditEvents} from "../../../modules/configuration/models/audit-events.model";
import {LicenseFilterModel, LicenseFormModel, LicenseModel} from "../../../modules/licenses/models/license.model";
import {HeatmapModel} from "../../models/heatmap.model";
import {
  DataExportDownloadModel,
  DataExportModel,
  RequestExportDataModel
} from "../../../modules/export-data/models/data-export.model";
import {PartnerAccount} from "../../../modules/partners/models/partner-account.model";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CorporateApiService {
  constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private store: Store) {}

  createMerchant(merchant: MerchantFormModel) {
    let url = `${API}/${routes?.merchants}`;

    return this.http
      .post<CommonResponse<PaginationModel<MerchantFormModel>>>(url, merchant);
  }

  editMerchant(merchant: MerchantFormModel, merchantId: string) {
    let url = `${API}/${routes?.merchants}/${merchantId}`;
    return this.http
      .patch<CommonResponse<PaginationModel<void>>>(url, merchant);
  }

  deleteMerchant(merchantId: string) {
    let url = `${API}/${routes?.merchants}/${merchantId}`;
    return this.http
      .delete<CommonResponse<PaginationModel<void>>>(url);
  }

  getMerchants(filter?: RequestDataFilterModel<MerchantFilterModel>) {
    let url = `${API}/${routes?.merchants}`;

    const filterParams = (!!filter) ? this.httpUtils.parseFilterParams(filter) : null;

    return this.http
      .get<CommonResponse<PaginationModel<MerchantModel>>>(url, {
        params: filterParams
      });
  }

//  ACCOUNTS

  getAccounts(filter: Partial<AccountsFilterModel>) {
    let url = `${API}/${routes?.accounts}`;
    return this.http.get<CommonResponse<PartnerAccount>>(url, {
      params: filter
    });
  }

  unlinkAccount(accountId: string, usersIds: string[]) {
    const url = `${API}/${routes?.accounts}/${accountId}/memberships`;
    return this.http.request<CommonResponse<AccountModel>>('delete', url, { body:  { users: usersIds }});
  }

  linkAccounts(accountId: string, usersIds: string[]) {
    const url = `${API}/${routes?.accounts}/${accountId}/memberships`;
    return this.http.post<CommonResponse<AccountModel>>(url, { users: usersIds });
  }

  getLinkedAccounts(refUser: string) {
    let url = `${API}/${routes?.users}/${refUser}/accounts`;

    return this.http.get<CommonResponse<AccountModel[]>>(url);
  }

  getAccountSummary(accountId: string, filter?: FilterAccountSummaryModel)  {
    const url = `${API}/${routes?.accounts}/${accountId}/summary`;
    return this.http.get<CommonResponse<number>>(url, {
      params: {...filter}
    });
  }

  getOperationalSummary(filter?: FilterAccountOperationalSummary) {
    const url = `${API}/${routes?.accounts}/reports/operational-summary`;
    return this.http.get<CommonResponse<AccountOperationalSummary>>(url, {
      params: {...filter}
    });
  }

  getTransactionsSummary(filter?: FilterAccountTransactionsSummary) {
    let url = `${API}/${routes?.accounts}/reports/transactions-summary`;
    return this.http.get<CommonResponse<AccountTransactionsSummary>>(url, {
      params: filter as any
    });
  }

  getTransactionsBalance(accountId) {
    let url = `${API}/${routes?.accounts}/${accountId}/balance`;

    return this.http.get<CommonResponse<PaginationModel<any>>>(url);
  }

  get currentAccount(): AccountModel {
    const state = this.store.selectSnapshot<AccountsStateModel>(AccountsState)
    return  state.currentAccount
  }

  // Billing Plans

  getBillingPlans(partnerId: number) {
    const url = `${API}/${routes?.billingPlans}?customerId=${partnerId}`;

    return this.http.get<CommonResponse<PaginationModel<PartnerBillingPlan>>>(url);
  }

  createBillingPlan(billingPlan: PartnerBillingPlan) {
    const url = `${API}/${routes?.billingPlans}/customers/${billingPlan.refCustomer}`;

    const {
      scheduleStartsAt,
      scheduleEndsAt,
      pixCashInCost,
      pixCashInRate,
      pixCashOutCost,
      pixCashOutRate,
      pixRefundCost,
      pixRefundRate,
    } = billingPlan;

    return this.http
      .post<CommonResponse<PartnerBillingPlan>>(
        url,
        {
          pixCashInCost,
          pixCashInRate,
          pixCashOutCost,
          pixCashOutRate,
          pixRefundCost,
          pixRefundRate,
          scheduleStartsAt,
          scheduleEndsAt
        });
  }

  updateBillingPlan(billingPlan: PartnerBillingPlan) {
    const url =
      `${API}/${routes?.billingPlans}/customers/${billingPlan.refCustomer}`;

    const {
      id,
      scheduleStartsAt,
      scheduleEndsAt,
      pixCashInCost,
      pixCashInRate,
      pixCashOutCost,
      pixCashOutRate,
      pixRefundCost,
      pixRefundRate,
    } = billingPlan;

    return this.http
      .patch(
        url,
        {
          id,
          scheduleStartsAt,
          scheduleEndsAt,
          pixCashInCost,
          pixCashInRate,
          pixCashOutCost,
          pixCashOutRate,
          pixRefundCost,
          pixRefundRate
        });
  }

  updateActiveBillingPlan(billingPlan: PartnerBillingPlan) {
    const url =
      `${API}/${routes?.billingPlans}/standard`;

    const {
      pixCashInCost,
      pixCashInRate,
      pixCashOutCost,
      pixCashOutRate,
      pixRefundCost,
      pixRefundRate,
    } = billingPlan;

    return this.http
      .patch(
        url,
        {
          pixCashInCost,
          pixCashInRate,
          pixCashOutCost,
          pixCashOutRate,
          pixRefundCost,
          pixRefundRate
        });
  }

  archiveBillingPlan(billingPlan: PartnerBillingPlan) {
    const url =
      `${API}/${routes?.billingPlans}/customers/${billingPlan.refCustomer}`;

    const { id } = billingPlan;

    return this.http
      .delete(
        url, {body: { id }});
  }

  //Customers

  getSites(partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}/sites`;
    return this.http
      .get<CommonResponse<PartnerSite[]>>(url);
  }

  deleteSite(partnerId: number, siteId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}/sites/${siteId}`;
    return this.http
      .delete<void>(url);
  }

  createSite(site: PartnerSite, partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}/sites`;
    return this.http
      .post<void>(url, site);
  }

  // Receiver

  createReceiver(receiver: ReceiverFormModel) {
    let url = `${API}/${routes?.receivers}`;

    return this.http
      .post<CommonResponse<PaginationModel<ReceiverFormModel>>>(url, receiver);
  }

  editReceiver(receiver: ReceiverFormModel, receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}`;
    return this.http
      .patch<CommonResponse<PaginationModel<void>>>(url, receiver);
  }

  deleteReceiver(receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}`;
    return this.http
      .delete<CommonResponse<PaginationModel<void>>>(url);
  }

  getReceivers(filter?: RequestDataFilterModel<ReceiverFormModel>) {
    let url = `${API}/${routes?.receivers}`;

    const filterParams = (!!filter) ? this.httpUtils.parseFilterParams(filter) : null;

    return this.http
      .get<CommonResponse<PaginationModel<ReceiverModel>>>(url, {
        params: filterParams
      });
  }

  getReceiverData(id: number) {
    let url = `${API}/${routes?.receivers}/${id}`;
    return this.http.get<CommonResponse<ReceiverFormModel>>(url);
  }

  getBanks(): Observable<BrBankModel[]> {
    return this.http.get<any>('assets/utils/br-banks.json');
  }

  getBankAccounts(receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}/accounts`;

    return this.http
      .get<CommonResponse<BankAccountModel[]>>(url);
  }

  deleteBankAccount(accountId: number, receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}/accounts/${accountId}`;

    return this.http
      .delete<CommonResponse<BankAccountModel>>(url);
  }

  createBankAccount(account: BankAccountModel, receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}/accounts`;

    return this.http
      .post<CommonResponse<BankAccountModel>>(url, account);
  }

  getCnaes(receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}/cnaes`;

    return this.http
      .get<CommonResponse<CnaeModel[]>>(url);
  }

  deleteCnae(id: number, receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}/cnaes/${id}`;

    return this.http
      .delete<CommonResponse<CnaeModel>>(url);
  }

  createCnae(account: CnaeModel, receiverId: number) {
    let url = `${API}/${routes?.receivers}/${receiverId}/cnaes`;

    return this.http
      .post<CommonResponse<CnaeModel>>(url, account);
  }

  // Transactions

  getTransactionsResume(accountId: string, filter?: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.accounts}/${accountId}/summary`;

    const filterParams = this.httpUtils.parseFilterParams(filter);
    return this.http
      .get<RecentTransactionsModel>(url, {
        params: filterParams
      });
  }

  getTransactions(type: TransactionsTypesEnum, accountId: string, filter?: RequestDataFilterModel<any>) {
    const url = `${API}/${routes?.accounts}/transactions/${type.toLowerCase()}`;

    if (accountId) {
      filter.where['accountId'] = accountId;
    }

    const filterParams = this.httpUtils.parseFilterParams(filter);

    return this.http
      .get<CommonResponse<PaginationModel<TransactionResponseModel>>>(url, {
        params: filterParams
      });
  }

  getTransactionsById(transactionId: string, type?: TransactionsTypesEnum, accountId?: string) {
    let url = `${API}/${routes?.accounts}/transactions/${type.toLowerCase()}/${transactionId}`;

    return this.http
      .get<CommonResponse<TransactionResponseModel>>(url);
  }

  syncTransaction(transactionsId) {
    let url = `${API}/${routes?.accounts}/transactions/${transactionsId}/sync`;
    return this.http
      .put<CommonResponse<string>>(url, null);
  }

  invokeWebhook(accountId: string, transactionsId) {
    let url = `${API}/${routes?.accounts}/transactions/${transactionsId}/invoke`;
    return this.http
      .put<CommonResponse<string>>(url, null);
  }

  // Transaction Requests
  getTransactionRequests(accountId, filter?: TransactionRequestFilterModel) {
    const url = `${API}/${routes?.accounts}/transaction-requests?${this.httpUtils.createQueryParamByObject(filter)}`;

    return this.http.get<CommonResponse<PaginationModel<TransactionRequestModel>>>(url);
  }

  getTransactionRequestDetails(transactionId) {
    const url = `${API}/${routes?.accounts}/transaction-requests/${transactionId}`;

    return this.http.get<CommonResponse<TransactionRequestModel>>(url);
  }

  reviewTransactionRequest(transactionId: string, transactionReview) {
    const url = `${API}/${routes?.accounts}/transaction-requests/review/${transactionId}`;

    return this.http.put<CommonResponse<any>>(url, transactionReview);
  }

  executePayment(transactionId: string, mfaCode?: string, bankingRoutingName?: string) {
    const url = `${API}/${routes?.accounts}/transaction-requests/execute/${transactionId}`;
    return this.http.put<CommonResponse<any>>(url, mfaCode ? { app: mfaCode, bankingRouting: bankingRoutingName } : null);
  }

  // Entry
  getEntries(obj: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.accounts}/entries`;
    const params = this.httpUtils.parseFilterParams(obj);
    return this.http.get(`${url}`, {
      params
    });
  }

  getPartnerEntries(obj: RequestDataFilterModel<any>, accountId: string) {
    let url = `${API}/${routes?.accounts}/${accountId}/entries`;
    const params = this.httpUtils.parseFilterParams(obj);

    return this.http.get(`${url}`, {
      params
    });
  }

  // security
  mfaValidate({ app }) {
    let url = `${API}/${routes?.mfa}/validate`;
    return this.http.post(`${url}`, { app }, {
      responseType: 'text'
    });
  }

  mfaAppSetupKey(): Observable<CommonResponse<any>> {
    let url = `${API}/${routes?.mfa}/app/setup`;
    return this.http.get<CommonResponse<any>>(`${url}`);
  }

  mfaAppConfirm(code: string): Observable<CommonResponse<any>> {
    let url = `${API}/${routes?.mfa}/app/setup/confirm`;
    return this.http.post<CommonResponse<any>>(`${url}`, { code });
  }

  mfaAppRevoke(code: string): Observable<CommonResponse<any>> {
    let url = `${API}/${routes?.mfa}/app/revoke`;
    return this.http.put<CommonResponse<any>>(`${url}`, { code });
  }


  getAccountSelectedBanks(accountId: number): Observable<CommonResponse<AccountConfigurationObject>> {
    let url = `${API}/${routes?.accounts}/${accountId}/config`;
    return this.http.get<CommonResponse<any>>(`${url}`);
  }

  setAccountOperationsBank(accountId: number, accountConfigurationObject: AccountConfigurationObject) {
    let url = `${API}/${routes?.accounts}/${accountId}/config`;
    return this.http.put<CommonResponse<any>>(`${url}`,
      { accountConfigurationObject }
    );
  }

  getAccountPixConfiguration(accountId: number): Observable<CommonResponse<AccountConfigurationObject>> {
    let url = `${API}/${routes?.accounts}/${accountId}/config`;
    return this.http.get<CommonResponse<any>>(`${url}`);
  }

  setPartnerAccountConfiguration(accountId: number, accountConfigurationObject: AccountConfigurationObject) {
    let url = `${API}/${routes?.accounts}/${accountId}/config`;
    return this.http.put<CommonResponse<any>>(`${url}`,
      { accountConfigurationObject }
    );
  }

  setAccountSecurityKey(accountId: number, accountUserPreferencesObject: AccountUserPreferencesObject) {
    let url = `${API}/${routes?.accounts}/${accountId}/preferences`;
    return this.http.put<CommonResponse<any>>(`${url}`,
      { accountUserPreferencesObject }
    );
  }

  getAccountSecurityKey(accountId: string) {
    let url = `${API}/${routes?.accounts}/${accountId}/preferences`;
    return this.http.get<CommonResponse<any>>(`${url}` );
  }

  // configurations
  getOperationBanks(): Observable<CommonResponse<BankOperator[]>> {
    let url = `${API}/${routes?.systemConfig}/banking`;
    return this.http.get<CommonResponse<any>>(`${url}`);
  }

  getSelectedBanks(): Observable<CommonResponse<SelectedBanksOperators>> {
    let url = `${API}/${routes?.systemConfig}/banking/routing`;
    return this.http.get<CommonResponse<any>>(`${url}`);
  }

  setOperationBank(pixCashIn: string[], pixCashOut: string[], forceGlobalCashIn: boolean, forceGlobalCashOut: boolean) {
    let url = `${API}/${routes?.systemConfig}/banking/routing`;
    return this.http.put<CommonResponse<any>>(`${url}`,
      { pixCashIn, pixCashOut, forceGlobalCashIn, forceGlobalCashOut }
    );
  }

  //policy
  getPolicyRules(filter: RequestDataFilterModel<Partial<PolicyFilterModel>>) {
    let url = `${API}/${routes?.policyRules}`;
    return this.http.get<CommonResponse<PaginationModel<PolicyModel>>>(`${url}`, {
      params: this.httpUtils.parseFilterParams(filter)
    });
  }

  addPolicyRules(rules: PolicyRulesModel) {
    let url = `${API}/${routes?.policyRules}`;
    return this.http.post<CommonResponse<any>>(`${url}`, rules);
  }

  editPolicy(policy: PolicyModel, policyId: string) {
    let url = `${API}/${routes?.policyRules}/${policyId}`;
    return this.http.put<CommonResponse<any>>(`${url}`, { ...policy });
  }

  removePolicyRules(policyId: string) {
    let url = `${API}/${routes?.policyRules}`;
    return this.http.delete<CommonResponse<any>>(`${url}/${policyId}`);
  }

  // API Access Keys
  getAccessKeys(filter: RequestDataFilterModel<any>) {
    const url = `${API}/${routes?.accounts}/api-keys?${this.httpUtils.parseFilterParams(filter)}`;

    return this.http.get<CommonResponse<PaginationModel<ApiKeyResponseModel>>>(url);
  }

  reviewAccessKeys(accessKey: string, status: ApiKeyReviewEnum, comment?: string) {
    const url = `${API}/${routes?.accounts}/api-keys/review/${accessKey}`;

    let params: any = { status };
    if (comment && status) {
      params = { status, comment };
    }

    return this.http.put(url, params);
  }

  // API Queries
  queryByCpf(cpf: string, forceRefresh: boolean) {
    const url = `${API}/${routes?.participants}/check/${cpf}?forceRefresh=${forceRefresh}`;

    return this.http.get<CommonResponse<ParticipantsCheckResponse>>(url);
  }

  queryTrioResetKey(key: string) {
    const url = `${API}/corporate/control-panel/banking/8f2f4d86-4c72-433f-b314-3d71bc5a8ca1/pix/reset-key/${key}`;
    return this.http.put<CommonResponse<any>>(url, '');
  }

  // Operations
  getOperationalTransactionRefund(configId: string, filter: RefundTransactionModel) {
    const url = `${API}/${routes?.controlPanel}/banking/${configId}/transactions/refund`;
    return this.http.put<CommonResponse<any>>(url, filter);
  }

  getOperationBankBalance(configId: string) {
    const url = `${API}/${routes?.controlPanel}/banking/${configId}/balance`;
    return this.http.get<CommonResponse<OperationBankBalance>>(url);
  }

  getCashinDocumentsByTransaction(configId: string, transactionId: string) {
    let url = `${API}/${routes?.controlPanel}/banking/${configId}/cashin/documents/${transactionId}`;
    return this.http.get<CommonResponse<any>>(url);
  }

  getCashoutDocumentsByTransaction(configId: string, transactionId: string) {
    let url = `${API}/${routes?.controlPanel}/banking/${configId}/cashout/documents/${transactionId}`;
    return this.http.get<CommonResponse<any>>(url);
  }

  getCashinDocumentsByE2E(configId: string, filter: any) {
    let url = `${API}/${routes?.controlPanel}/banking/${configId}/cashin/documents`;
    return this.http.get<CommonResponse<any>>(url, {
      params: filter
    });
  }

  getCashoutDocumentsByE2E(configId: string, filter: any) {
    let url = `${API}/${routes?.controlPanel}/banking/${configId}/cashout/documents`;
    return this.http.get<CommonResponse<any>>(url, {
      params: filter
    });
  }

  getAccountOverview(filter: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.accounts}/overview`;

    const params = this.httpUtils.parseFilterParams(filter);

    return this.http.get<CommonResponse<PaginationModel<Account>>>(url, {
      params
    });
  }

  getAuditEvents(filter: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.auditEvents}`;

    const params = this.httpUtils.parseFilterParams(filter);

    return this.http.get<CommonResponse<PaginationModel<AuditEvents>>>(url, {
      params
    });
  }

  getCloudLogs(filter: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.cloudLogs}`;

    const params = this.httpUtils.parseFilterParams(filter);

    return this.http.get<CommonResponse<any>>(url, {
      params
    });
  }

  // Data Export
  getDataExport(filter: RequestDataFilterModel<any>) {
    let url = `${API}/${routes.exports}`;

    const params = this.httpUtils.parseFilterParams(filter);

    return this.http.get<CommonResponse<PaginationModel<DataExportModel>>>(url, {
      params
    });
  }

  requestTransactionsExport(request: RequestExportDataModel) {
    let url = `${API}/${routes.exports}/transactions`;
    return this.http.post<CommonResponse<PaginationModel<DataExportModel>>>(url, request);
  }

  requestAcamExport(request: RequestExportDataModel) {
    let url = `${API}/${routes.exports}/acams`;
    return this.http.post<CommonResponse<PaginationModel<DataExportModel>>>(url, request);
  }

  downloadDataExport(id: string) {
    let url = `${API}/${routes.exports}/${id}/download`;

    return this.http
      .get<CommonResponse<DataExportDownloadModel>>(url)
      .pipe(
        switchMap((res) => {
          return this.http.get(res.data.url, { responseType: 'text' });
        })
      );
  }

  // Licenses
  createLicense(license: LicenseFormModel) {
    let url = `${API}/${routes?.merchants}`;

    return this.http
      .post<CommonResponse<PaginationModel<LicenseFormModel>>>(url, license);
  }

  editLicense(license: LicenseFormModel, licenseId: string) {
    let url = `${API}/${routes?.merchants}/${licenseId}`;
    return this.http
      .patch<CommonResponse<PaginationModel<void>>>(url, license);
  }

  deleteLicense(merchantId: string) {
    let url = `${API}/${routes?.merchants}/${merchantId}`;
    return this.http
      .delete<CommonResponse<PaginationModel<void>>>(url);
  }

  getLicenses(filter?: RequestDataFilterModel<LicenseFilterModel>) {
    let url = `${API}/${routes?.merchants}`;

    const filterParams = (!!filter) ? this.httpUtils.parseFilterParams(filter) : null;

    return this.http
      .get<CommonResponse<PaginationModel<LicenseModel>>>(url, {
        params: filterParams
      });
  }

  getLicenseById(id: string) {
    let url = `${API}/${routes?.merchants}/${id}`;

    return this.http.get<CommonResponse<LicenseModel>>(url);
  }

  getHeatmapDataCorp(filter: FilterAnalyticsHeatmap){
    let url = `${API}/corporate/analytics/heatmap`;

    const params = this.httpUtils.parseFilterParams(filter);
    return this.http.get<CommonResponse<PaginationModel<HeatmapModel>>> (url, {
      params
    });
  }

  getListBanks(){
    let url = `${API}/corporate/system-config/banking`

    return this.http.get<CommonResponse<any>>(url)
  }
}
