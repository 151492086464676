import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter, Inject,
  Input, OnDestroy,
  Output,
  QueryList, TemplateRef,
  ViewChild
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {MjxTableDataSource} from './mjx-table-datasource';
import {MjxTableColumnDefinition} from "./models/mjx-table.model";
import {MjxTableColumnTypesEnum} from "./enums/mjx-table-column-types.enum";
import {MjxTableEventsModel} from "./models/mjx-table-events.model";
import {defaultCurrency} from "../../utils/language-utils";
import {MjxMatColumnDirective} from "./directives/mjx-mat-column.directive";
import {TableStorageService} from "./services/table-storage.service";
import {MJX_TABLE_PAGE_SIZES} from "../../tokens/mjx-table-page-sizes.token";
import { get } from 'lodash';
import {MjxTableEventsEnum} from "./enums/mjx-table-events.enum";
import {MjxMatPaginatorIntl} from "./services/mjx-mat-paginator-intl.service";

export interface CustomMatColumn {
  def: string;
  name: string;
  show: boolean;
  disabled: boolean;
}

@Component({
  selector: 'mjx-table',
  templateUrl: './mjx-table.component.html',
  styleUrls: ['./mjx-table.component.scss']
})
export class MjxTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ContentChildren(MjxMatColumnDirective) columnsRef: QueryList<MjxMatColumnDirective>;

  @Input() set dataSource(data: MjxTableDataSource<any>) {
    if (data) {
      this.tableDataSource = data;
      this.updateTableDataSource(data?.data ?? []);
    }
  }

  @Input() showColumnsFilter = false;
  @Input() tableName: string;
  @Input() displayedColumns: string[];
  @Input() columnsDefinitions: MjxTableColumnDefinition[];
  @Input() defaultPageSize = 10;
  @Input() defaultPageSizeOptions = [5, 10, 20];
  @Input() hiddenPagination = false;
  @Input() showFooter = false;
  @Input() hafTable = false;
  @Input() columnsWithExpand: boolean = false;
  @Input() infinityTable: boolean = false;
  @Input() totalTable: boolean = false;
  @Input() disableTable: boolean = false;

  @Output() tableEvents = new EventEmitter<MjxTableEventsModel>();

  tableDataSource: MjxTableDataSource<any>;
  columnTypes = MjxTableColumnTypesEnum;
  defaultCurrency = defaultCurrency();
  columnsToDisplay: CustomMatColumn[] = [];
  expandedElement: any | null;

  constructor(
    private tableStorage: TableStorageService,
    private ref: ChangeDetectorRef,
    @Inject(MJX_TABLE_PAGE_SIZES) public pageSizes: number[],
  ) {
    if (pageSizes) {
      this.defaultPageSizeOptions = pageSizes;
    }
  }

  get columnsRefs(): { [key: string]: TemplateRef<any> } {
    if (this.columnsRef) {
      const columnTemplates: { [key: string]: TemplateRef<any> } = {};
      for (const columnDefinition of this.columnsRef.toArray()) {
        columnTemplates[columnDefinition.columnName] = columnDefinition.columnTemplate;
      }

      return columnTemplates;
    } else {
      return {};
    }
  }

  ngOnDestroy(): void {
    if (this.paginator) {
      const intl = (this.paginator._intl as MjxMatPaginatorIntl)

      intl.reloadSubs.unsubscribe();
      intl.translateSubs.unsubscribe();
      this.paginator.ngOnDestroy();
    }
  }

  ngAfterViewInit(): void {
    this.updateTableDataSource(this.tableDataSource);
    if (this.showColumnsFilter) {
      this.updateCustomColumns();
    }
    this.ref.detectChanges();
  }

  updateCustomColumns() {
    const hasColumnsSave: CustomMatColumn[] = this.tableStorage.loadTableColumns(this.tableName);

    if (hasColumnsSave) {
      // Check if columns in storage are in definitions, if not delete storage
      const mapDefsFromStorage = hasColumnsSave.map(c => c.def);
      const mapDefsFromDefinitions = this.columnsDefinitions.map(c => c.column);
      const defsEquals = mapDefsFromStorage.every(item =>mapDefsFromDefinitions.includes(item));
      if (defsEquals) {
        this.columnsToDisplay = hasColumnsSave;
      } else {
        this.tableStorage.deleteTableColumn(this.tableName);
        this.columnsToDisplay = this.getCusMatColumns();
      }
    } else {
      this.columnsToDisplay = this.getCusMatColumns();
    }
  }

  hasColumnsFilter() {
    return this.columnsToDisplay.find(c => !c.show);
  }

  isAllColumnsFiltered() {
    return this.columnsToDisplay.every(c => !c.show);
  }

  getCusMatColumns(): CustomMatColumn[] {
    const customMatColumns: CustomMatColumn[] = [];
    this.displayedColumns.forEach(column => {
      const columnDef = this.columnsDefinitions.find(d => d.column === column);
      if (columnDef) {
        customMatColumns.push({
          def: column,
          name: columnDef.name,
          disabled: !columnDef.enableHideColumn,
          show: columnDef.show ?? true
        })
      }
    });
    return customMatColumns;
  }

  getDisplayedColumns(): string[] {
    return this.showColumnsFilter ?
      this.columnsToDisplay.filter((cd) => cd.show).map((cd) => cd.def) :
      this.displayedColumns;
  }

  listenChange(event) {
  }

  emitEvent(event: string, tableData: any) {
    this.tableEvents.emit({name: event, data: tableData});
  }

  emitButtonEvent(event) {
    this.emitEvent(event.name, event.data);
  }

  resetPaginator(reset: boolean) {
    if (reset) {
      this.paginator.firstPage();
    }
  }

  checkLabel(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  toggleRow(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  private async updateTableDataSource(data: any) {
    if (this.table && data) {
      this.tableDataSource.sort = this.sort;
      this.tableDataSource.paginator = this.paginator;

      this.table.dataSource = this.tableDataSource;
    }
  }

  saveStatesToStorage() {
    this.tableStorage.saveTableColumns(this.tableName, this.columnsToDisplay);
  }

  getValue(item: any, columnDef: MjxTableColumnDefinition) {
    return get(item, columnDef.property);
  }


  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.tableEvents.emit({name: MjxTableEventsEnum.SCROLL_END, data: null})
    }
  }

  expandRow(row: any) {
    if (this.columnsWithExpand) {
      this.expandedElement = (this.expandedElement === row) ? null : row
      this.tableEvents.emit({name: MjxTableEventsEnum.EXPAND_ROW, data: row})
    }
  }
}
