import {NavigationOption} from "../models/navigation-option.model";
import {Roles} from "./roles-list";
import {CompanyUtils} from "./company-utils";

const analyticsMenus: NavigationOption[] = [
  { title: 'NAVBAR.OPTIONS.HOME', icon: 'poll', routerLink: 'home', roles: Roles.MENU_ROUTES.HOME },
  { title: 'NAVBAR.OPTIONS.HEATMAP', icon: 'dashboard', routerLink: 'heatmap', roles: Roles.MENU_ROUTES.HEATMAP }
];

const financesOptions: NavigationOption[] = [
  { title: 'NAVBAR.OPTIONS.SALES', icon: 'download', routerLink: 'transactions/sales',
    roles: Roles.MENU_ROUTES.SALES },
  { title: 'NAVBAR.OPTIONS.WITHDRAWS', icon: 'upload', routerLink: 'transactions/withdraws',
    roles: Roles.MENU_ROUTES.WITHDRAWS },
  { title: 'NAVBAR.OPTIONS.TRANSACTIONS', icon: 'swap_vert', routerLink: 'transactions/list',
    roles: Roles.MENU_ROUTES.TRANSACTIONS },
  {
    title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES', icon: 'store',
    menus: [
      { title: 'NAVBAR.OPTIONS.RECEIVERS', icon: 'store', routerLink: 'receivers',
        roles: Roles.MENU_ROUTES.RECEIVERS.LIST },
      { title: 'NAVBAR.OPTIONS.PAYMENTS', icon: 'payments', routerLink: 'transactions/payments',
        roles: Roles.MENU_ROUTES.PAYMENTS }
    ]
  },
  { title: 'NAVBAR.OPTIONS.ENTRIES', icon: 'currency_exchange', routerLink: 'entries',
    roles: Roles.MENU_ROUTES.ENTRIES
  },
  { title: 'NAVBAR.OPTIONS.DATA_EXPORT.TITLE', icon: 'swap_vert',
    menus: [
      { title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_TRANSACTIONS',
        icon: 'summarize', routerLink: 'export-data/transactions',
        roles: Roles.MENU_ROUTES.DATA_EXPORT
      },
      { title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_ACAM',
        icon: 'ballot', routerLink: 'export-data/acam',
        roles: Roles.MENU_ROUTES.DATA_EXPORT
      }
    ]
  },
];

const entitiesOptions: NavigationOption[] = [
  { title: 'NAVBAR.OPTIONS.USERS.TITLE', icon: 'account_circle', routerLink: 'users',
    roles: Roles.MENU_ROUTES.USERS.MANAGEMENT },
  // { title: 'NAVBAR.OPTIONS.USERS.PROFILES', icon: 'description', routerLink: 'users/profile-permissions',
  //   roles: Roles.MENU_ROUTES.USERS.PROFILES },
  { title: 'NAVBAR.OPTIONS.MERCHANTS', icon: 'store', routerLink: 'licenses', roles: Roles.MENU_ROUTES.MERCHANTS },
  { title: 'NAVBAR.OPTIONS.PARTNERS', icon: 'group_work', routerLink: 'partners',
    roles: Roles.MENU_ROUTES.PARTNERS },
  { title: 'NAVBAR.OPTIONS.ACCOUNTS', icon: 'wallet', routerLink: 'accounts',
    roles: Roles.MENU_ROUTES.ACCOUNTS },
  { title: 'NAVBAR.OPTIONS.BANKS', icon: 'account_balance', routerLink: 'banks',
  roles: Roles.MENU_ROUTES.BANKS }
];

const managerOptions: NavigationOption[] = [
  {
    title: 'NAVBAR.OPTIONS.INTEGRATION.TITLE', icon: 'settings', roles: Roles.MENU_ROUTES.INTEGRATION.ALL,
    menus: [
      { title: 'NAVBAR.OPTIONS.INTEGRATION.WEBHOOKS', icon: 'send', routerLink: 'integration/webhooks',
        roles: Roles.MENU_ROUTES.INTEGRATION.WEBHOOKS },
      {
        title: 'NAVBAR.OPTIONS.INTEGRATION.DOCUMENTATION',
        icon: 'description',
        external: true,
        routerLink: CompanyUtils.documentationUrl,
        roles:  Roles.MENU_ROUTES.INTEGRATION.DOCUMENTATION
      },
      { title: 'NAVBAR.OPTIONS.INTEGRATION.ACCESS_KEYS', icon: 'key', routerLink: 'integration/access-keys',
        roles: Roles.MENU_ROUTES.INTEGRATION.ACCESS_KEYS },
    ]
  },
  {
    title: 'NAVBAR.OPTIONS.OPERATIONAL.TITLE', icon: 'done_all',
    menus: [
      { title: 'NAVBAR.OPTIONS.OPERATIONAL.BANKING', icon: 'account_balance', roles: Roles.MENU_ROUTES.OPERATIONAL, routerLink: 'operational' },
      { title: 'NAVBAR.OPTIONS.CONFIG.BANKS', icon: 'account_balance', routerLink: 'configuration/banks',
        roles: Roles.MENU_ROUTES.CONFIG.BANKS },
      { title: 'NAVBAR.OPTIONS.CONFIG.POLICY', icon: 'security', routerLink: 'configuration/security',
        roles: Roles.MENU_ROUTES.CONFIG.POLICY },
      { title: 'NAVBAR.OPTIONS.CONFIG.SYSTEM_LOGS', icon: 'person_search', routerLink: 'cloud',
        roles: Roles.MENU_ROUTES.CONFIG.CLOUD },
      { title: 'NAVBAR.OPTIONS.CONFIG.AUDIT', icon: 'person_search', routerLink: 'configuration/audit',
        roles: Roles.MENU_ROUTES.CONFIG.AUDIT },
      { title: 'NAVBAR.OPTIONS.QUERIES', icon: 'search', routerLink: 'queries',
        roles: Roles.MENU_ROUTES.QUERY}
    ]
  }
]

export const lateralNavbarOptions: NavigationOption[] = [
  { title: 'NAVBAR.LATERAL.ANALYTICS', icon: 'insert_chart', menus: analyticsMenus, roles: Roles.MENU_ROUTES.HOME },
  { title: 'NAVBAR.LATERAL.FINANCIAL', icon: 'payments', menus: financesOptions, roles: Roles.MENU_ROUTES.FINANCES },
  { title: 'NAVBAR.LATERAL.ENTITIES', icon: 'linked_services', menus: entitiesOptions, roles: Roles.MENU_ROUTES.ENTITIES },
  { title: 'NAVBAR.LATERAL.ADMIN', icon: 'settings', menus: managerOptions, roles: Roles.MENU_ROUTES.CONFIGURATIONS }
]

const navbarOptions: NavigationOption[] = [
  { title: 'NAVBAR.OPTIONS.HOME', icon: 'poll', routerLink: 'home',
    roles: Roles.MENU_ROUTES.HOME },
  { title: 'NAVBAR.OPTIONS.HEATMAP', icon: 'dashboard', routerLink: 'heatmap', roles: Roles.MENU_ROUTES.HEATMAP },
  { title: 'NAVBAR.OPTIONS.SALES', icon: 'download', routerLink: 'transactions/sales',
    roles: Roles.MENU_ROUTES.SALES },
  { title: 'NAVBAR.OPTIONS.WITHDRAWS', icon: 'upload', routerLink: 'transactions/withdraws',
    roles: Roles.MENU_ROUTES.WITHDRAWS },
  {
    title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES', icon: 'payments',
    menus: [
      { title: 'NAVBAR.OPTIONS.RECEIVERS', icon: 'store', routerLink: 'receivers',
        roles: Roles.MENU_ROUTES.RECEIVERS.LIST },
      { title: 'NAVBAR.OPTIONS.PAYMENTS', icon: 'payments', routerLink: 'transactions/payments',
        roles: Roles.MENU_ROUTES.PAYMENTS }
    ]
  },
  { title: 'NAVBAR.OPTIONS.ENTRIES', icon: 'swap_vert', routerLink: 'entries',
    roles: Roles.MENU_ROUTES.ENTRIES },
  { title: 'NAVBAR.OPTIONS.TRANSACTIONS', icon: 'swap_vert', routerLink: 'transactions/list',
    roles: Roles.MENU_ROUTES.TRANSACTIONS },
  { title: 'NAVBAR.OPTIONS.DATA_EXPORT', icon: 'swap_vert', routerLink: 'export-data',
    roles: Roles.MENU_ROUTES.DATA_EXPORT },
  { title: 'NAVBAR.OPTIONS.ACCOUNTS', icon: 'wallet', routerLink: 'accounts',
    roles: Roles.MENU_ROUTES.ACCOUNTS },
  { title: 'NAVBAR.OPTIONS.BANKS', icon: 'account_balance', routerLink: 'banks',
  roles: Roles.MENU_ROUTES.BANKS },
  { title: 'NAVBAR.OPTIONS.ADMINISTRATIVE', icon: 'admin_panel_settings',
    roles: Roles.MENU_ROUTES.ADMINISTRATIVE,
    menus: [
      { title: 'NAVBAR.OPTIONS.PARTNERS', icon: 'group_work', routerLink: 'partners',
        roles: Roles.MENU_ROUTES.PARTNERS },
      { title: 'NAVBAR.OPTIONS.MERCHANTS', icon: 'store', routerLink: 'licenses', roles: Roles.MENU_ROUTES.MERCHANTS },
      { title: 'NAVBAR.OPTIONS.USERS.TITLE', icon: 'person',
        menus: [
          { title: 'NAVBAR.OPTIONS.USERS.MANAGEMENT', icon: 'manage_accounts', routerLink: 'users',
            roles: Roles.MENU_ROUTES.USERS.MANAGEMENT }
        ]
      },
      {
        title: 'NAVBAR.OPTIONS.OPERATIONAL.TITLE', icon: 'done_all',
        menus: [
          { title: 'NAVBAR.OPTIONS.OPERATIONAL.BANKING', icon: 'account_balance', roles: Roles.MENU_ROUTES.OPERATIONAL, routerLink: 'operational' },
          { title: 'NAVBAR.OPTIONS.INTEGRATION.ACCESS_KEYS', icon: 'key', routerLink: 'integration/access-keys',
            roles: Roles.MENU_ROUTES.INTEGRATION.ACCESS_KEYS },
        ]
      },
      { title: 'NAVBAR.OPTIONS.CONFIG.TITLE', icon: 'display_settings', menus: [
          { title: 'NAVBAR.OPTIONS.CONFIG.BANKS', icon: 'account_balance', routerLink: 'configuration/banks',
            roles: Roles.MENU_ROUTES.CONFIG.BANKS },
          { title: 'NAVBAR.OPTIONS.CONFIG.POLICY', icon: 'security', routerLink: 'configuration/security',
            roles: Roles.MENU_ROUTES.CONFIG.POLICY },
          { title: 'NAVBAR.OPTIONS.CONFIG.AUDIT', icon: 'person_search', routerLink: 'configuration/audit',
            roles: Roles.MENU_ROUTES.CONFIG.AUDIT },
        ]
      }
    ]
  },
  { title: 'NAVBAR.OPTIONS.QUERIES', icon: 'search', routerLink: 'queries',
    roles: Roles.MENU_ROUTES.QUERY}
];

const partnersNavbarOptions: NavigationOption[] = [
  { title: 'NAVBAR.OPTIONS.HOME', icon: 'poll', routerLink: 'home',
    roles: Roles.MENU_ROUTES.HOME },
  { title: 'NAVBAR.OPTIONS.HEATMAP', icon: 'dashboard', routerLink: 'heatmap', roles: Roles.MENU_ROUTES.HEATMAP },
  { title: 'NAVBAR.OPTIONS.SALES', icon: 'download', routerLink: 'transactions/sales',
    roles: Roles.MENU_ROUTES.SALES },
  { title: 'NAVBAR.OPTIONS.WITHDRAWS', icon: 'upload', routerLink: 'transactions/withdraws',
    roles: Roles.MENU_ROUTES.WITHDRAWS },
  {
    title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES', icon: 'payments',
    menus: [
      { title: 'NAVBAR.OPTIONS.RECEIVERS', icon: 'store', routerLink: 'receivers',
        roles: Roles.MENU_ROUTES.RECEIVERS.LIST },
      { title: 'NAVBAR.OPTIONS.PAYMENTS', icon: 'payments', routerLink: 'transactions/payments',
        roles: Roles.MENU_ROUTES.PAYMENTS }
    ]
  },
  { title: 'NAVBAR.OPTIONS.ENTRIES', icon: 'swap_vert', routerLink: 'entries',
    roles: Roles.MENU_ROUTES.ENTRIES },
  { title: 'NAVBAR.OPTIONS.USERS.TITLE', icon: 'person',
    menus: [
      { title: 'NAVBAR.OPTIONS.USERS.MANAGEMENT', icon: 'manage_accounts', routerLink: 'users',
        roles: Roles.MENU_ROUTES.USERS.MANAGEMENT },
      // { title: 'NAVBAR.OPTIONS.USERS.PROFILES', icon: 'description', routerLink: 'users/profile-permissions',
      //   roles: Roles.MENU_ROUTES.USERS.PROFILES }
    ]
  },
  {
    title: 'NAVBAR.OPTIONS.INTEGRATION.TITLE', icon: 'settings',
    menus: [
      { title: 'NAVBAR.OPTIONS.INTEGRATION.WEBHOOKS', icon: 'send', routerLink: 'integration/webhooks',
        roles: Roles.MENU_ROUTES.INTEGRATION.WEBHOOKS },
      {
        title: 'NAVBAR.OPTIONS.INTEGRATION.DOCUMENTATION',
        icon: 'description',
        external: true,
        routerLink: CompanyUtils.documentationUrl,
        roles:  Roles.MENU_ROUTES.INTEGRATION.DOCUMENTATION
      }
    ]
  },
  {
    title: 'NAVBAR.OPTIONS.OPERATIONAL.TITLE', icon: 'done_all',
    menus: [
      { title: 'NAVBAR.OPTIONS.INTEGRATION.ACCESS_KEYS', icon: 'key', routerLink: 'integration/access-keys',
        roles: Roles.MENU_ROUTES.INTEGRATION.ACCESS_KEYS },
    ]
  },
  { title: 'NAVBAR.OPTIONS.CONFIG.TITLE', icon: 'display_settings', menus: [
      { title: 'NAVBAR.OPTIONS.CONFIG.POLICY', icon: 'security', routerLink: 'configuration/security',
        roles: Roles.MENU_ROUTES.CONFIG.POLICY }
    ]
  },
  { title: 'NAVBAR.OPTIONS.QUERIES', icon: 'search', routerLink: 'queries',
    roles: Roles.MENU_ROUTES.QUERY}
];

export const partnersMenuList = partnersNavbarOptions;
export const menuList = navbarOptions;
