import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {HttpUtilsService} from 'src/app/shared/services/http-utils.service';
import {CommonResponse} from "../../models/response.model";
import {
  AccountOperationalSummary, AccountTransactionsSummary,
  FilterAccountOperationalSummary, FilterAccountSummaryModel, FilterAccountTransactionsSummary, FilterAnalyticsHeatmap
} from "../../../core/models/account.model";
import {TransactionsTypesEnum} from "../../enums/transactions-types.enum";
import {TransactionResponseModel} from "../../../modules/transactions/models/transaction-response.model";
import {RequestDataFilterModel} from "../../models/request-filter.model";
import {RecentTransactionsModel} from "../../../modules/transactions/models/recent-transactions.model";
import {PaginationModel} from "../../models/pagination.model";
import { TransactionRequestFilterModel } from 'src/app/modules/transactions/models/transaction-request-filter.model';
import { TransactionRequestModel } from 'src/app/modules/transactions/models/transaction-request.model';
import {AccountUserPreferencesObject} from "../../../modules/partners/models/account-user-preferences-object.model";
import {PolicyFilterModel} from "../../../modules/configuration/models/policy/policy-filter.model";
import {PolicyModel} from "../../../modules/configuration/models/policy/policy.model";
import {PolicyRulesModel} from "../../../modules/configuration/models/policy/policy-rules.model";
import { HeatmapModel } from '../../models/heatmap.model';
@Injectable({
  providedIn: 'root',
})
export class PartnersApiService {

  empty_url = ''

  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private store: Store
  ) {
  }

  getAccounts<T>(filter) {
    return this.http.get<CommonResponse<T>>(this.empty_url, {
      params: filter
    });
  }

  getOperationalSummary(filter?: FilterAccountOperationalSummary) {
    // let url = `${API}/${routes?.accounts}/${filter.accountId}/reports/operational-summary`;
    delete filter.accountId;
    return this.http.get<CommonResponse<AccountOperationalSummary>>(this.empty_url, {
      params: {...filter}
    });
  }

  getTransactions(type: TransactionsTypesEnum, accountId: string, filter?: RequestDataFilterModel<any>) {
    return this.http
      .get<CommonResponse<PaginationModel<TransactionResponseModel>>>(this.empty_url, {
        params: this.httpUtils.parseFilterParams(filter)
      });
  }

  getTransactionsById(transactionId: string, type?: TransactionsTypesEnum, accountId?: string) {
    return this.http
      .get<CommonResponse<TransactionResponseModel>>(this.empty_url);
  }

  getTransactionsResume(accountId: string, filter?: RequestDataFilterModel<any>) {
    const filterParams = this.httpUtils.parseFilterParams(filter);
    return this.http
      .get<RecentTransactionsModel>(this.empty_url, {
        params: filterParams
      });
  }

  getAccountSummary(accountId: string, filter?: FilterAccountSummaryModel)  {
    return this.http.get<CommonResponse<number>>(this.empty_url, {
      params: {...filter}
    });
  }

  invokeWebhook(accountId: string, transactionsId) {
    return this.http
      .put<CommonResponse<string>>(this.empty_url, null);
  }

  // Transaction Requests
  getTransactionRequests(accountId: string, filter?: TransactionRequestFilterModel) {
    return this.http.get<CommonResponse<PaginationModel<TransactionRequestModel>>>(this.empty_url);
  }

  getTransactionRequestDetails(accountId: string, transactionId) {
    return this.http.get<CommonResponse<TransactionRequestModel>>(this.empty_url);
  }

  createTransactionRequest(accountId: string, transactionRequest: TransactionRequestModel) {
    return this.http
      .post<CommonResponse<TransactionRequestModel>>(this.empty_url, null);
  }

  deleteTransactionRequest(accountId: string, transactionRequestId: string) {
    return this.http
      .delete(this.empty_url);
  }

  cancelPayment(accountId: string, transactionRequestId: string) {
    return this.http
      .post(this.empty_url, null);
  }

  getTransactionsSummary(filter?: FilterAccountTransactionsSummary) {
    delete filter.accountId;
    return this.http.get<CommonResponse<AccountTransactionsSummary>>(this.empty_url, {
      params: filter as any
    });
  }

  // Entry
  getEntries(obj: RequestDataFilterModel<any>) {
    delete obj.where.accountId;
    const params = this.httpUtils.parseFilterParams(obj);
    return this.http.get(this.empty_url, {
      params
    });
  }

  getPartnerEntries(obj: RequestDataFilterModel<any>, accountId: string) {
    return this.http.get<CommonResponse<any>>(this.empty_url);
  }

  getAccountSecurityKey(accountId: string) {
    let url = this.empty_url;
    return this.http.get<CommonResponse<any>>(`${url}` );
  }

  setAccountSecurityKey(accountId: string, accountUserPreferencesObject: AccountUserPreferencesObject) {
    let url = this.empty_url;
    return this.http.put<CommonResponse<any>>(`${url}`,
      { accountUserPreferencesObject }
    );
  }

  //policy
  getPolicyRules(filter: RequestDataFilterModel<Partial<PolicyFilterModel>>, accountId: string) {
    let url = this.empty_url;
    return this.http.get<CommonResponse<PaginationModel<PolicyModel>>>(`${url}`, {
      params: this.httpUtils.parseFilterParams(filter)
    });
  }

  addPolicyRules(rules: PolicyRulesModel, accountId: string) {
    let url = this.empty_url;
    return this.http.post<CommonResponse<any>>(`${url}`, rules);
  }

  editPolicy(policy: PolicyModel, policyId: string, accountId: string) {
    let url = this.empty_url;
    return this.http.put<CommonResponse<any>>(`${url}`, { ...policy });
  }

  removePolicyRules(policyId: string, accountId: string) {
    let url = this.empty_url;
    return this.http.delete<CommonResponse<any>>(`${url}/${policyId}`);
  }

  getHeatmapData(filter: FilterAnalyticsHeatmap){
    let url = this.empty_url;

    const params = this.httpUtils.parseFilterParams(filter);
    return this.http.get<CommonResponse<PaginationModel<HeatmapModel>>> (url, {
      params
    });
  }

  // API Queries
  queryByCpf(cpf: string) {
    const url = this.empty_url;

    return this.http.get<CommonResponse<PaginationModel<any>>>(url);
  }

}
