export const TranslationModulesNames =  {
  Login: 'LOGIN',
  Home: 'HOME',
  Heatmap: 'HEATMAP',
  Transactions: 'TRANSACTIONS',
  Users: 'USERS',
  Sales: 'SALES',
  Integration: 'INTEGRATION',
  Receivers: 'RECEIVERS',
  DataExport: 'DATA-EXPORT',
  Info: 'INFO',
  Shared: 'SHARED',
  Errors: 'ERRORS',
  Entries: 'ENTRIES',
  Partners: 'PARTNERS',
  Merchants: 'MERCHANTS',
  Reset: 'RESET',
  Config: 'CONFIG',
  Queries: 'QUERIES',
  Operational: 'OPERATIONAL',
  Accounts: 'ACCOUNTS',
  Banks: 'BANKS',
  Licenses: 'MERCHANTS',
  Cloud: 'CLOUDS'
}
