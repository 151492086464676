import {IConfig} from "ngx-mask";
import {isEnglish} from "./language-utils";

const enMaskConfig: Partial<IConfig> = {
  decimalMarker: '.',
  thousandSeparator: ','
}

const brMaskConfig: Partial<IConfig> = {
  decimalMarker: ',',
  thousandSeparator: '.'
}

export const maskConfigFactory: () => Partial<IConfig> = () => {
  return isEnglish() ? enMaskConfig : brMaskConfig
};

