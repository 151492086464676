import {StorageKeysEnum} from "../enums/storage-keys.enum";
import {TranslationModulesEnum} from "../enums/translation-modules.enum";

export function isEnglish() {
  const localLang = localStorage.getItem(StorageKeysEnum.Language);

  return (localLang === 'en-us')
}

export function currentLang() {
  return localStorage.getItem(StorageKeysEnum.Language);
}

export function translationByModuleFactory(module: TranslationModulesEnum, translations: Object) {
  return { [module]: translations }
}

export function defaultCurrency() {
  // return isEnglish() ? 'USD' : 'BRL';
  return 'BRL';
}
